import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";

const DoughnutChart = (props) => {
  const expenseData = props.expenseData;
  console.log(expenseData);
  const options = {
    chart: {
      type: "donut",
    },
    labels: ["Miscellaneous", "Packaging", "Logistics", "One Time Charge"],
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div>
        <span>Rs. ${series[seriesIndex]}</span>
      </div>`;
      },
    },
    legend: {
      position: "bottom",
      offsetY: 5,
    },
  };

  const series = [
    expenseData.expense_Miscellaneous,
    expenseData.expense_Packaging,
    expenseData.expense_Logistics,
    expenseData.expense_One_Time_Charge,
  ];

  return (
    <Card>
      <CardBody style={{ height: "484.19px" }}>
        <CardTitle tag="h5">Expense Distribution</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Expense Report
        </CardSubtitle>
        <div style={{ marginTop: "70px", marginBottom: "10px" }}>
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={350}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default DoughnutChart;
