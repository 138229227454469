import { BrowserRouter, Route, Routes } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import Starter from "./views/Starter";
import Error from "./Error";
import About from "./views/About";
import Alerts from "./views/ui/Alerts";
import Buttons from "./views/ui/Buttons";
import Badges from "./views/ui/Badges";
import Breadcrumbs from "./views/ui/Breadcrumbs";
import Cards from "./views/ui/Cards";
import Grid from "./views/ui/Grid";
import Tables from "./views/ui/Tables";
import Forms from "./views/ui/Forms";
// import CalendarComponent from "./views/Calender/CalendarComponent";
import Client from "./views/ui/Client";
import EditClient from "./views/ui/EditClient";
import ClientList from "./views/ui/ClientList";
import AddB2B from "./views/B2B/addB2B";
import B2B from "./views/B2B/B2B";
import SoldB2B from "./views/B2B/soldB2b";
import B2C from "./views/B2C/B2C";
import AddB2C from "./views/B2C/addB2C";
import Cart from "./views/B2C/cart";
import CartSold from "./views/B2C/cart_sold";
import AddMaterial from "./views/B2C/add_material";
import AddB2CWithMaterials from "./views/B2C/addB2C_withMaterials";
import AddExpense from "./views/Expense/AddExpense";
import BarcodePage from "./layouts/BarcodePage";
// import News from "./pages/WebNews";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Website routs */}

        {/* admin route */}
        <Route path="" element={<FullLayout />}>
          <Route path="" element={<Starter />} />
          <Route path="about" element={<About />} />
          <Route path="alerts" element={<Alerts />} />
          <Route path="B2B" element={<B2B />} />
          <Route path="SoldB2B" element={<SoldB2B />} />
          <Route path="barcode" element={<BarcodePage />} />

          <Route path="addB2B" element={<AddB2B />} />
          <Route path="B2C" element={<B2C />} />
          <Route path="addB2C" element={<AddB2C />} />
          <Route path="cart" element={<Cart />} />
          <Route path="cart-sold" element={<CartSold />} />
          <Route path="add-material" element={<AddMaterial />} />
          <Route
            path="addB2C-with-material"
            element={<AddB2CWithMaterials />}
          ></Route>
          <Route path="add-expense" element={<AddExpense />}></Route>

          {/* <Route path="client-list" element={<ClientList />} />
          <Route path="client" element={<Client />} />
          <Route path="client-list/:clientId" element={<EditClient />} />
          <Route path="badges" element={<Badges />} />
          <Route path="buttons" element={<Buttons />} />
          <Route path="cards" element={<Cards />} />
          <Route path="table" element={<Tables />} />
          <Route path="forms" element={<Forms />} />
          <Route path="grid" element={<Grid />} /> */}
          {/* <Route path="breadcrumbs" element={<Breadcrumbs />} /> */}
          {/* <Route path="calender" element={<CalendarComponent />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
