import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Table, Spinner, Modal } from "reactstrap";
import axios from "axios";
import { Tab } from "bootstrap";

const CartSold = () => {
  const [cartList, setCartList] = useState([]);
  const [loading, setLoading] = useState([false]);

  useEffect(() => {
    const fetchCartData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/api/B2CTable/getCart");

        if (response.status === 200) {
          setCartList(response.data);
        } else {
          setCartList([]);
          console.log("Server Error : ", response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartData();
  }, []);

  const [showModal, setModalFlag] = useState(false);
  const [selectedCartItem, setSelectedCartItem] = useState(null);
  //OPEN CART MODEL
  const openModal = (cart) => {
    console.log("Opened Modal");
    setSelectedCartItem(cart);
    setModalFlag(true);
  };

  const closeModal = () => {
    setModalFlag(false);
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Cart Sold
      </CardTitle>
      <CardBody>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <Spinner size="lg" color="light" />
          </div>
        ) : (
          //show cart list
          <Table>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Total</th>
                {/*<td>Items</td>*/}
              </tr>
            </thead>
            <tbody>
              {cartList.map((cart) => (
                <tr key={cart.cartId} onClick={() => openModal(cart)}>
                  <td style={{ cursor: "pointer" }}>{cart.customerName}</td>
                  {/*<td>{JSON.stringify(item.items)}</td>*/}
                  <td>{cart.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Modal isOpen={showModal} toggle={() => closeModal()} size="xl">
          <div>
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Product Name</th>
                  <th>Product Type</th>
                  <th>Crystal Type</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Weight</th>
                  <th>Sale Price</th>
                  <th>Listing Date</th>
                </tr>
              </thead>
              <tbody>
                {selectedCartItem &&
                  selectedCartItem.items.map((tdata, index) => (
                    <tr key={index} className="border-top">
                      <td>{tdata.itemCode}</td>
                      <td>{tdata.productName}</td>
                      <td>{tdata.productType}</td>
                      <td> {tdata.crystalType}</td>
                      <td> {tdata.quantity}</td>
                      <td> {tdata.status}</td>
                      <td>{tdata.weight}</td>
                      <td>{tdata.quantity * tdata.salePrice}</td>
                      <td>
                        {" "}
                        {new Date(tdata.listingDate).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal>
      </CardBody>
    </Card>
  );
};
export default CartSold;
