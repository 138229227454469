import React from 'react';
import { Button, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Logo from './Logo';
import { Link, useLocation } from 'react-router-dom';

const navigation = [
  {
    title: "Dashboard",
    href: "",
    icon: "bi bi-speedometer2",
  },
  {
    category: "Business",
    items: [
      {
        title: "B2B",
        href: "B2B",
        icon: "bi bi-bell",
      },
      {
        title: "B2B Sold",
        href: "SoldB2B",
        icon: "bi bi-bell",
      },
      {
        title: "B2C",
        href: "B2C",
        icon: "bi bi-bell",
      },
      {
        title: "B2C With Materials",
        href: "addB2C-with-material",
        icon: "bi bi-bell",
      },
    ]
  },
 
  {
    category: "Cart",
    items:[
      {
    title: " Add to Cart",
    href: "cart",
    icon: "bi bi-cart3",
  },
  {
    title: "Cart Sold",
    href: "cart-sold",
    icon: "bi bi-cart3",
  },
]
},
  {
    title: "Add Material",
    href: "add-material",
    icon: "bi bi-bell",
  },
  {
    title: "Add Expense",
    href: "add-expense",
    icon: "bi bi-bell",
  },
  // ... other navigation items
];

const Sidebar = () => {
  const showMobilemenu = () => {
    document.getElementById('sidebarArea').classList.toggle('showSidebar');
  };
  let location = useLocation();

  return (
    <div className="p-3">
      <div className="d-flex align-items-center mx-5">
        <Logo style={{marginLeft:'10px'}}/>
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none"
            onClick={() => showMobilemenu()}
          ></Button>
        </span>
      </div>
      <div className="pt-4 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            navi.category ? (
              <UncontrolledDropdown nav inNavbar key={index} className="sidenav-bg">
                <DropdownToggle nav caret className="nav-link text-secondary py-3">
                  <i className="bi bi-briefcase"></i>
                  <span className="ms-3 d-inline-block">{navi.category}</span>
                </DropdownToggle>
                <DropdownMenu>
                  {navi.items.map((item, idx) => (
                    <DropdownItem key={idx}>
                      <Link
                        to={item.href}
                        className={
                          location.pathname === item.href
                            ? "text-primary nav-link py-3"
                            : "nav-link text-secondary py-3"
                        }
                      >
                        <i className={item.icon}></i>
                        <span className="ms-3 d-inline-block">{item.title}</span>
                      </Link>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <NavItem key={index} className="sidenav-bg">
                <Link
                  to={navi.href}
                  className={
                    location.pathname === navi.href
                      ? "text-primary nav-link py-3"
                      : "nav-link text-secondary py-3"
                  }
                >
                  <i className={navi.icon}></i>
                  <span className="ms-3 d-inline-block">{navi.title}</span>
                </Link>
              </NavItem>
            )
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
