import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  Card,
  // CardImg,
  // CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  // CardGroup,
  Button,
  Row,
  // Col,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const B2B = () => {
  const [modal, setModal] = useState(false);
  // const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formData, setFormData] = useState({
    customerName: "",
    amountSold: 0,
    purchasedDate: "",
    remarks: "",
    price: 0,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/B2BTable");
        setTableData(response.data); // Update the tableData state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const fetchData = async (start, end) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `api/B2BTable/sortDate?start=${start}&end=${end}`
      );
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const toggleModal = (product = null) => {
    if (product) {
      setFormData({
        ...product,
        amountSold: product.remainingQuantity,
        //purchasedDate: product.purchasedDate.split("T")[0], // Ensure correct date format for input[type="date"]
      });
    } else {
      setFormData({
        id: "",
        customerName: "",
        amountSold: 0,
        purchasedDate: "",
        remarks: "",
        price: 0,
      });
    }
    setModal(!modal);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // Empty dependency array means this effect runs once when the component mounts
  // const cardStyle = {
  //   height: "500px", // Adjust the height as needed
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id, ...dataToSend } = formData;
    try {
      await axios.post(`/api/B2BTable/Sell/${id}`, dataToSend);
      toggleModal();
      // Refresh the table data here if necessary
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <Row>
      <div>
        <FormGroup>
          <Label for="startDate">Start Date</Label>
          <Input
            type="date"
            name="startDate"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">End Date</Label>
          <Input
            type="date"
            name="endDate"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </FormGroup>
        <Button
          color="primary"
          onClick={() => fetchData(startDate, endDate)}
          className="my-3 d-block"
        >
          Search
        </Button>
        <Link to="/addB2B">
        <Button className="my-3" color="success">
          Add Product
        </Button>
      </Link>
      </div>
    

      <div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <Card>
            <CardBody>
              <CardTitle tag="h5">Bulk Products Listing</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                List of Bulk Products inventory
              </CardSubtitle>

              <Table
                style={{ fontSize: "12px" }}
                className="no-wrap mt-3 align-middle table-bordered border-primary"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Supplier</th>
                    <th>Purchased Price</th>
                    <th>Sale Price</th>
                    <th>Amount Sold</th>
                    <th>Remaining</th>
                    <th>Bulk Quantity</th>
                    <th>Purchased Date</th>
                    <th>Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata, index) => (
                    <tr key={index} className={"border-top" +(
                      tdata.remainingQuantity > 0
                        ? "table-success"
                        : "table-danger"
                    ) }>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {tdata.itemCode}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {tdata.productName}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {tdata.productType}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {tdata.supplier}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {tdata.purchasePrice}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {tdata.salePrice}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {tdata.amountSold}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {tdata.remainingQuantity}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {tdata.bulkQuantity}
                      </td>
                      <td
                        className={
                          tdata.remainingQuantity > 0
                            ? "table-success"
                            : "table-danger"
                        }
                      >
                        {" "}
                        {new Date(tdata.purchasedDate).toLocaleDateString()}
                      </td>
                      <Button
                        className="btn my-2 md btn-primary"
                        style={{border:'1px solid black', color:'blue'}}
                        onClick={() => toggleModal(tdata)}
                        size="sm"
                      >
                        Sell
                      </Button>
                    </tr>
                  ))}
                </tbody>
                <Modal isOpen={modal} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>Edit Product</ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="CustomerName">Customer Name</Label>
                      <Input
                        id="CustomerName"
                        name="CustomerName"
                        value={formData.customerName}
                        onChange={handleInputChange}
                      />
                      <Label for="AmountSold">Amount Sold</Label>
                      <Input
                        id="amountSold"
                        name="amountSold"
                        value={formData.amountSold}
                        onChange={handleInputChange}
                      />
                      <Label for="remarks">Remarks</Label>
                      <select
                        id="remarks"
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option value="" disabled>
                          Select your option
                        </option>
                        <option value="Cash">Cash</option>
                        <option value="Esewa">Esewa</option>
                        <option value="Company Bank">Company Bank</option>
                        <option value="Personal Bank">Personal Bank</option>
                      </select>

                      <Label for="Price">Price</Label>
                      <Input
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleInputChange}
                      />
                      <Label for="PurchasedDate">Select Date</Label>
                      <Input
                        type="date"
                        name="purchasedDate"
                        id="PurchasedDate"
                        placeholder="Purchased Date"
                        onChange={handleInputChange}
                      />
                      {/* Repeat for other fields */}
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>{" "}
                    <Button color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Table>
            </CardBody>
          </Card>
        )}
      </div>
    </Row>
  );
};

export default B2B;
