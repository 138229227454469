import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Table } from "reactstrap";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";
import bwipjs from "bwip-js";
import BarcodeGenerator from "./BarcodeGenerator";
import "./print.css";

const BarcodePage = () => {
  const [tableData, setTableData] = useState([]);

  const handleChange = (index, event) => {
    const newData = [...tableData];
    newData[index].quantity = event.target.value;
    setTableData(newData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/barcodes");
        setTableData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const generateBarcode = (text) => {
    try {
      const canvas = document.createElement("canvas");
      bwipjs.toCanvas(canvas, {
        bcid: "code128",
        text: text,
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });
      return canvas.toDataURL("image/png");
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        {tableData.map((tdata, index) => {
          const barcodeRows = [];
          for (let i = 0; i < tdata.quantity; i += 5) {
            const barcodes = [];
            for (let j = 0; j < 5; j++) {
              if (i + j < tdata.quantity) {
                barcodes.push(
                  <View
                    key={`${index}-${i + j}`}
                    style={styles.barcodeContainer}
                  >
                    <Text style={styles.itemCode}>{tdata.productID}</Text>
                    <Image
                      style={styles.barcode}
                      src={generateBarcode(tdata.productID)}
                    />
                  </View>
                );
              }
            }
            barcodeRows.push(
              <View key={`${index}-${i}`} style={styles.row}>
                {barcodes}
              </View>
            );
          }
          return barcodeRows;
        })}
      </Page>
    </Document>
  );

  const handlePrint = async () => {
    const doc = <MyDocument />;
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url, "_blank");
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
      };
    }
  };

  return (
    <div>
      <Table
        className="no-wrap mt-3 align-middle table-bordered border-primary table-striped"
        style={{ fontSize: "12px" }}
        responsive
        borderless
      >
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Quantity</th>
            <th>Barcode</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((tdata, index) => (
            <tr key={index} className="border-top">
              <td style={{ fontSize: "14px", fontWeight: "500" }}>
                {tdata.productID}
              </td>
              <td>
                <input
                  type="number"
                  value={tdata.quantity}
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
              <td>
                <BarcodeGenerator
                  value={tdata.productID}
                  width={2}
                  height={50}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button onClick={handlePrint}>Print Barcode</Button>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },
  barcodeContainer: {
    width: "18%", 
    marginBottom: 10,
    padding: 1,
    borderBottom: "1px solid #eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  itemCode: {
    fontSize: 12,
    marginBottom: 5,
  },
  barcode: {
    width: "100%",
    height: "auto",
  },
});

export default BarcodePage;
