import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import React, { useState } from "react";

import axios from "axios";

const AddExpense = () => {
  const [saving, setSaving] = useState(false);
  const [expenseData, setExpenseData] = useState({
    particulars: "",
    remarks: "",
    paymentMethods: "",
    category: "",
    amount: 0,
    dateTime: "",
  });

  const handleChangeMaterial = (e) => {
    const { name, value } = e.target;
    let newExpenseData = { ...expenseData, [name]: value };
    setExpenseData(newExpenseData);
  };

  const saveMaterial = async () => {
    setSaving(true);
    try {
      console.log(expenseData);
      const response = await axios.post(
        "/api/Expense",
        JSON.stringify(expenseData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        alert("Expense added successfully");
      } else {
        console.log("Server Error : ", response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Add Expense
      </CardTitle>
      <CardBody>
        <Form>
          <FormGroup>
            <Label for="particulars">Particulars</Label>
            <Input
              id="particulars"
              name="particulars"
              placeholder="Particulars"
              type="text"
              onChange={handleChangeMaterial}
            />
          </FormGroup>
          <FormGroup>
            <Label for="remarks">Remarks</Label>
            <Input
              id="remarks"
              name="remarks"
              placeholder="remarks"
              type="text"
              onChange={handleChangeMaterial}
            />
          </FormGroup>
          <FormGroup>
            <Label for="category">category</Label>
            <select
              id="category"
              name="category"
              onChange={handleChangeMaterial}
              defaultValue=""
              className="form-control"
            >
              <option value="" disabled>
                Select your option
              </option>
              <option value="Miscellaneous">Miscellaneous</option>
              <option value="Packaging">Packaging</option>
              <option value="Logistics">Logistics</option>
              <option value="One Time Charge">Logistics</option>
            </select>
          </FormGroup>

          <FormGroup>
            <Label for="dateTime">Expense Date</Label>
            <Input
              id="dateTime"
              name="dateTime"
              placeholder="Expense Date"
              type="date"
              onChange={handleChangeMaterial}
            />
          </FormGroup>

          <FormGroup>
            <Label for="amount">amount</Label>
            <Input
              id="amount"
              name="amount"
              placeholder="amount"
              type="number"
              onChange={handleChangeMaterial}
            />
          </FormGroup>

          <FormGroup>
            <Label for="paymentMethods">paymentMethods</Label>
            <select
              id="paymentMethods"
              name="paymentMethods"
              onChange={handleChangeMaterial}
              defaultValue=""
              className="form-control"
            >
              <option value="" disabled>
                Select your option
              </option>
              <option value="Cash">Cash</option>
              <option value="Esewa">Esewa</option>
              <option value="Company Bank">Company Bank</option>
              <option value="Personal bank">Personal bank</option>
            </select>
          </FormGroup>
        </Form>
        <Button className="mt-2" onClick={saving ? null : saveMaterial}>
          {saving ? (
            <>
              <Spinner size="sm" /> Saving...
            </>
          ) : (
            "Add Expense"
          )}
        </Button>
      </CardBody>
    </Card>
  );
};
export default AddExpense;
