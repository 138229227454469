import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import BarcodeGenerator from "../../layouts/BarcodeGenerator";

import {
  Card,
  // CardImg,
  // CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  // CardGroup,
  Button,
  Row,
  Col,
  Spinner,
  Table,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // FormGroup,
  // Label,
  Input,
} from "reactstrap";

const B2C = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/B2CTable");
        setTableData(response.data); // Update the tableData state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const fetchData = async (searchTerm = "") => {
    setLoading(true);
    try {
      // Adjust the API endpoint or parameters as necessary to accommodate searching
      const response = await axios.get(
        `/api/B2CTable/SearchProduct/${searchTerm}`
      );
      setTableData(response.data); // Update the tableData state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  // Empty dependency array means this effect runs once when the component mounts
  // const cardStyle = {
  //   height: "500px", // Adjust the height as needed
  // };
  const handleBarcode=()=>{
    navigate('/barcode');
  }
  return (
    <Row>
      <Col md="6" className="mb-3">
        <Input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by product name..."
        />
      </Col>
      <Col md="6">
        <Button color="primary" onClick={() => fetchData(searchTerm)}>
          Search
        </Button>
      </Col>
      <Col md="6">
        <Link to="/addB2C">
          <Button className="btn my-3" color="success">
            Add Product
          </Button>
        </Link>
      </Col>

      <div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <Card>
            <CardBody>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle tag="h5">Sold B2B Products</CardTitle>
                <Button className="btn my-3 d-flex" color="primary" onClick={handleBarcode}>
                  Generate Barcode
                </Button>
              </div>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                List of bulk products sold
              </CardSubtitle>
              <Table
                className="no-wrap mt-3 align-middle table-bordered border-primary table-striped"
                style={{ fontSize: "12px" }}
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Product Name</th>
                    <th>Product Type</th>
                    <th>Crystal Type</th>
                    <th>Quantity</th>
                    <th>Status</th>
                    <th>Weight</th>
                    <th>Purchase Price</th>
                    <th>Sale Price</th>
                    <th>Listing Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata, index) => (
                    <>
                    <tr key={index} className="border-top">
                      <td>{tdata.itemCode}</td>
                      <td>{tdata.productName}</td>
                      <td>{tdata.productType}</td>
                      <td> {tdata.crystalType}</td>
                      <td> {tdata.quantity}</td>
                      <td> {tdata.status}</td>
                      <td>{tdata.weight}</td>
                      <td>{tdata.purchasePrice}</td>
                      <td>{tdata.salePrice}</td>
                      <td>
                        {" "}
                        {new Date(tdata.listingDate).toLocaleDateString()}
                      </td>
                    </tr>
                    {/* <BarcodeGenerator value={tdata.itemCode}/> */}
                    </>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </div>
    </Row>
  );
};

export default B2C;
