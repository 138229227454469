import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import React, { useState } from "react";

import axios from "axios";

const AddMaterial = () => {
  const [saving, setSaving] = useState(false);
  const [materialData, setMaterialData] = useState({
    materialName: "",
    materialCode: "",
    costPerGram: 0,
    totalCost: 0,
    weight: 0,
    purchaseDate: "",
    Remarks: "",
    Source: "",
  });

  const handleChangeMaterial = (e) => {
    const { name, value } = e.target;
    let newMaterialData = { ...materialData, [name]: value };

    if (name === "materialName") {
      const newMaterialCode = generateMaterialCode(
        name === "materialName" ? value : materialData.materialName
      );
      newMaterialData = { ...newMaterialData, materialCode: newMaterialCode };
    }

    if (name === "costPerGram" || name === "weight") {
      newMaterialData = {
        ...newMaterialData,
        totalCost: newMaterialData.costPerGram * newMaterialData.weight,
      };
    }

    setMaterialData(newMaterialData);
  };

  const generateMaterialCode = (materialName) => {
    const nameKey = materialName.substring(0, 3).toUpperCase();
    // Format the item code
    return `MAT-${nameKey}`;
  };

  const saveMaterial = async () => {
    setSaving(true);
    try {
      const response = await axios.post(
        "/api/B2CTable/AddMaterials",
        JSON.stringify(materialData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        alert("Materials added successfully");
      } else {
        console.log("Server Error : ", response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Materials required for the product
      </CardTitle>
      <CardBody>
        <Form>
          <FormGroup>
            <Label for="materialCode">Material Code</Label>
            <Input
              id="materialCode"
              name="materialCode"
              type="text"
              value={materialData.materialCode}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="materialName">Material Name</Label>
            <Input
              id="materialName"
              name="materialName"
              placeholder="Material Name"
              type="text"
              onChange={handleChangeMaterial}
            />
          </FormGroup>
          <FormGroup>
            <Label for="costPerGram">Cost per Gram</Label>
            <Input
              id="costPerGram"
              name="costPerGram"
              placeholder="Cost per Gram"
              type="number"
              onChange={handleChangeMaterial}
            />
          </FormGroup>
          <FormGroup>
            <Label for="totalCost">Total Cost</Label>
            <Input
              id="totalCost"
              name="totalCost"
              placeholder="Total Cost"
              type="number"
              value={materialData.totalCost}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="weight">Weight (in grams)</Label>
            <Input
              id="weight"
              name="weight"
              placeholder="Weight"
              type="number"
              onChange={handleChangeMaterial}
            />
          </FormGroup>

          <FormGroup>
            <Label for="purchaseDate">Purchase Date</Label>
            <Input
              id="purchaseDate"
              name="purchaseDate"
              placeholder="Purchase Date"
              type="date"
              onChange={handleChangeMaterial}
            />
          </FormGroup>

          <FormGroup>
            <Label for="Source">Source</Label>
            <Input
              id="Source"
              name="Source"
              placeholder="Source"
              type="text"
              onChange={handleChangeMaterial}
            />
          </FormGroup>

          <FormGroup>
            <Label for="Remarks">Remarks</Label>
            <select
              id="Remarks"
              name="Remarks"
              onChange={handleChangeMaterial}
              defaultValue=""
              className="form-control"
            >
              <option value="" disabled>
                Select your option
              </option>
              <option value="Miscellaneous">Miscellaneous</option>
              <option value="Packaging">Packaging</option>
              <option value="Logistics">Logistics</option>
              <option value="One Time Charge">Logistics</option>
            </select>
          </FormGroup>
        </Form>
        <Button className="mt-2" onClick={saving ? null : saveMaterial}>
          {saving ? (
            <>
              <Spinner size="sm" /> Saving...
            </>
          ) : (
            "Save Material"
          )}
        </Button>
      </CardBody>
    </Card>
  );
};
export default AddMaterial;
