import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Axios from "axios";

import { BrowserRouter as Router, useNavigate } from "react-router-dom";

const AddB2B = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ItemCode: "",
    ProductName: "",
    ProductType: "",
    Supplier: "",
    PurchasedPrice: "",
    SalePrice: "",
    BulkQuantity: "",
    PurchasedDate: "",
    Remarks: "",
    Source: "",
  });
  const generateItemCode = (productName, bulkQuantity) => {
    const nameKey = productName.substring(0, 3).toUpperCase();
    // Format the item code
    return `BULK-${nameKey}-${bulkQuantity}KG`;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value };

    if (name === "ProductName" || name === "BulkQuantity") {
      const newItemCode = generateItemCode(
        name === "ProductName" ? value : formData.ProductName,
        name === "BulkQuantity" ? value : formData.BulkQuantity
      );
      newFormData = { ...newFormData, ItemCode: newItemCode };
    }

    setFormData(newFormData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      const selectedDate = new Date(formData.Date);
      const isoDateString = selectedDate.toISOString();
      formDataToSend.append("itemCode", formData.ItemCode);
      formDataToSend.append("productName", formData.ProductName);
      formDataToSend.append("productType", formData.ProductType);
      formDataToSend.append("supplier", formData.Supplier);
      formDataToSend.append("purchasePrice", formData.PurchasedPrice);
      formDataToSend.append("salePrice", formData.SalePrice);
      formDataToSend.append("bulkQuantity", formData.BulkQuantity);
      formDataToSend.append("remarks", formData.Remarks);
      formDataToSend.append("source", formData.Source);
      formDataToSend.append("purchasedDate", isoDateString);
      for (const entry of formDataToSend.entries()) {
        const [fieldName, fieldValue] = entry;
        console.log(`${fieldName}: ${fieldValue}`);
      }
      //   const response = await Axios.post("/api/B2BTable", formDataToSend);
      const response = await Axios.post("/api/B2BTable", formDataToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("formDataToSend:", formDataToSend);

      console.log("Response:", response.data);
      if (response.status === 201) {
        // Success
        alert("Product added successfully!");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        // Display an error message
        alert("Failed to add product. Please try again.");
      }
      // Add any additional logic you need after a successful submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
            Add B2B Product
          </CardTitle>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="ItemCode">Item Code</Label>
                <Input
                  id="ItemCode"
                  name="ItemCode"
                  type="text"
                  value={formData.ItemCode}
                  readOnly
                />
              </FormGroup>
              <FormGroup>
                <Label for="ProductName">Product Name</Label>
                <Input
                  id="ProductName"
                  name="ProductName"
                  placeholder="Product Name"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="ProductType">Product Type</Label>
                <Input
                  id="ProductType"
                  name="ProductType"
                  placeholder="Product Type"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Supplier">Supplier</Label>
                <Input
                  id="Supplier"
                  name="Supplier"
                  placeholder="Supplier"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="PurchasedPrice">Purchased Price</Label>
                <Input
                  id="PurchasedPrice"
                  name="PurchasedPrice"
                  placeholder="Purchased Price"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="SalePrice">SalePrice</Label>
                <Input
                  id="SalePrice"
                  name="SalePrice"
                  placeholder="Sale Price"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Source">Source</Label>
                <Input
                  id="Source"
                  name="Source"
                  placeholder="Source"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Remarks">Remarks</Label>
                <select
                  id="Remarks"
                  name="Remarks"
                  onChange={handleChange}
                  defaultValue=""
                  className="form-control"
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="Miscellaneous">Miscellaneous</option>
                  <option value="Packaging">Packaging</option>
                  <option value="Logistics">Logistics</option>
                  <option value="One Time Charge">Logistics</option>
                </select>
              </FormGroup>

              <FormGroup>
                <Label for="BulkQuantity">Bulk Quantity</Label>
                <Input
                  id="BulkQuantity"
                  name="BulkQuantity"
                  placeholder="Bulk Quantity"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="PurchasedDate">Select Date</Label>
                <Input
                  type="date"
                  name="Date"
                  id="PurchasedDate"
                  placeholder="Purchased Date"
                  onChange={handleChange}
                />
              </FormGroup>

              <Button type="submit" className="mt-2">
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AddB2B;
