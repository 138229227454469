import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import axios from "axios";

const SalesChart = () => {
  const [incomeDatesList, setIncomeDatesList] = useState([]);
  const [incomeList, setIncomeList] = useState([]);
  const [expenseDatesList, setExpenseDatesList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  useEffect(() => {
    const fetchIncome = async () => {
      const today = new Date();

      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();
      const month = `${todayYear}-${String(todayMonth).padStart(2, "0")}`;
      try {
        const response = await axios.get(`/api/B2CTable/${month}/${todayYear}`);
        console.log(response.data);
        if (response.status == 200) {
          setExpenseDatesList(response.data.expenseDates);
          const roundedResponse = response.data.expenseValue.map(
            (expenseValue) => parseFloat(expenseValue.toFixed(2))
          );
          setExpenseList(roundedResponse);
          setIncomeDatesList(response.data.incomeDates);
          const roundedResponseIncome = response.data.incomeValue.map(
            (incomeValue) => parseFloat(incomeValue.toFixed(2))
          );
          setIncomeList(roundedResponseIncome);
        } else {
          console.log("Response : ", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };
    fetchIncome();
  }, []);
  const allDates = [
    ...new Set([...incomeDatesList, ...expenseDatesList]),
  ].sort();

  const chartoptions = {
    series: [
      {
        name: "Expense",
        data: [...expenseList],
      },
      {
        name: "Income",
        data: [...incomeList],
      },
      //   {
      //     name: "Oneplue 9",
      //     data: [0, 11, 32, 45, 32, 34, 52, 41],
      //   },
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },

      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: allDates,
      },
    },
  };
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Sales Summary</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Yearly Expense Report
        </CardSubtitle>
        <Chart
          type="area"
          width="100%"
          height="390"
          options={chartoptions.options}
          series={chartoptions.series}
        ></Chart>
      </CardBody>
    </Card>
  );
};

export default SalesChart;
