import axios from "axios";
import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Form,
  Button,
  Table,
  FormFeedback,
  Spinner,
} from "reactstrap";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const Cart = () => {
  const [customerDetails, setClientDetails] = useState({
    customerName: "",
    email: "",
    remarks: "",
    purchaseDate: "",
  });
  const [editedPrices, setEditedPrices] = useState([]);

  const [productDetails, setProductDetails] = useState({
    itemCode: "",
    productName: "",
    productType: "",
    crystalType: "",
    status: "",
    weight: 0,
    salePrice: 0,
    purchasePrice: 0,
    quantity: 0,
    listingDate: "",
  });

  const [productList, setProductList] = useState([]);
  // const [quantityList, setQuantityList] = useState([]);
  const [quantityList, setQuantityList] = useState([]);
  const handleChange = (textFieldInput, fieldName) => {
    const newValue = textFieldInput.target
      ? textFieldInput.target.value
      : textFieldInput.label;
    setClientDetails({
      ...customerDetails,
      [fieldName]: newValue,
    });
  };
  useEffect(() => {
    const initialPrices = productList.map((tdata) => tdata.salePrice);
    setEditedPrices(initialPrices);
  }, [productList]);

  const handleProductForm = (textFieldInput, fieldName) => {
    const newValue = textFieldInput.target
      ? textFieldInput.target.value
      : textFieldInput.label;

    setProductDetails({
      ...productDetails,
      [fieldName]: newValue,
    });
  };
  const handleKeyDown = (event, fieldName) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the form from being submitted

      fetchProductDetails(); // Call your function to fetch product details

      // Clear the input field by setting its state
      setProductDetails({
        ...productDetails,
        [fieldName]: "",
      });
    }
  };
  //get product details
  const fetchProductDetails = async () => {
    if (
      productDetails.itemCode.trim() === null ||
      productDetails.itemCode.trim() === ""
    ) {
      alert("Enter product code");
    } else if (
      productList.some((item) => item.itemCode === productDetails.itemCode)
    ) {
      alert("Item already added");
    } else {
      try {
        const response = await axios.get(
          `/api/B2CTable/Stock/${productDetails.itemCode}`
        );
        if (response.status === 200) {
          const newProductDetails = {
            ...productDetails,
            productName: response.data.productName,
            productType: response.data.productType,
            crystalType: response.data.crystalType,
            status: response.data.status,
            weight: response.data.weight,
            salePrice: response.data.salePrice,
            purchasePrice: response.data.purchasePrice,
            quantity: 1,
            listingDate: response.data.listingDate,
          };
          // setQuantityList(...quantityList, response.data.quantity);
          // console.log("after update : ", quantityList);
          // for (let i = 0; i <= quantityList.length; i++) {
          //   console.log(quantityList[i]);
          // }
          //setProductDetails(newProductDetails);
          setProductList([...productList, newProductDetails]);
          setQuantityList([...quantityList, response.data.quantity]);
          console.log("product list : ", productList);
        } else if (response.status === 404) {
          alert("Product is out of stock");
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
  };
  const [postingCart, setPostingCart] = useState(false);
  const postCart = async () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setPostingCart(true);

    try {
      const total = calculateTotal();
      const today = new Date();
      const isoDateString = today.toISOString().slice(0, 19);
      console.log("DATE: " + isoDateString);
      const cartObject = {
        customerName: customerDetails.customerName,
        total: total,
        remarks: customerDetails.remarks,
        purchasedDate: isoDateString,
        items: productList,
      };
      console.log(JSON.stringify(cartObject));
      const response = await axios.post(
        `/api/B2CTable/postCart?email=${customerDetails.email}`,
        JSON.stringify(cartObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        alert("Data added successfully");
      } else {
        alert("Server Error", response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPostingCart(false);
    }
  };

  const calculateTotal = () => {
    let total = productList.reduce((acc, product) => {
      return acc + product.quantity * product.salePrice;
    }, 0);
    console.log("Total amount: ", total);
    return total;
  };

  const [error, setError] = useState({});

  const validateForm = () => {
    const validationErrors = {};
    if (!customerDetails.customerName) {
      validationErrors.name = "Customer name is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = emailRegex.test(customerDetails.email);
    if (!customerDetails.email) {
      validationErrors.email = "Email field is required";
    } else if (!validEmail) {
      validationErrors.email = "Email format not correct!";
    }

    setError(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  //method to remove item from list
  const onDelete = (index) => {
    const updatedProductList = [...productList];
    updatedProductList.splice(index, 1); // Remove the item at the specified index
    setProductList(updatedProductList);
  };
  const handlePriceChange = (event, index) => {
    const newSalePrice = parseFloat(event.target.value); // Parse the new sale price directly from input

    // Check for NaN and set to 0 if true
    if (isNaN(newSalePrice)) {
      return;
    }

    // Update the productList with the new sale price
    const newProductList = productList.map((item, idx) =>
      idx === index ? { ...item, salePrice: newSalePrice } : item
    );
    setProductList(newProductList);
  };

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup>
            <Label for="client-name">Customer Name</Label>
            <Input
              id="client-name"
              name="customerName"
              type="text"
              placeholder="Enter Client Name"
              value={customerDetails.customerName}
              onChange={(name) => handleChange(name, "customerName")}
              invalid={!!error.name}
            />
            <FormFeedback>{error.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="cleint-email">Client Email</Label>
            <Input
              id="client-email"
              name="email"
              type="email"
              placeholder="Enter Customer Email Address"
              value={customerDetails.email}
              onChange={(email) => handleChange(email, "email")}
              invalid={!!error.email}
            />
            <FormFeedback>{error.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="remarks">Remarks</Label>
            <select
              id="remarks"
              name="remarks"
              value={customerDetails.remarks}
              onChange={(event) => handleChange(event, "remarks")}
              className="form-control"
            >
              <option value="" disabled>
                Select your option
              </option>
              <option value="Cash">Cash</option>
              <option value="Esewa">Esewa</option>
              <option value="Company Bank">Company Bank</option>
              <option value="Personal Bank">Personal Bank</option>
            </select>
            <FormFeedback>{error.remarks}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="product-code">Product Code</Label>
            <Input
              id="product-code"
              name="code"
              type="text"
              placeholder="Enter Product Code"
              value={productDetails.itemCode}
              onChange={(code) => handleProductForm(code, "itemCode")}
              onKeyDown={(event) => handleKeyDown(event, "itemCode")}
            />
          </FormGroup>
        </Form>
        {/* <Button className="btn" color="primary" onClick={fetchProductDetails}>
          Get Product Details
        </Button> */}
        {productList.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <h6>Product List</h6>
          </div>
        )}
        {productList.length > 0 && (
          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Product Name</th>
                <th>Product Type</th>
                <th>Crystal Type</th>
                <th>Quantity</th>
                <th>Status</th>
                <th>Weight</th>
                <th>Sale Price</th>
                <th>Listing Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {productList.map((tdata, index) => (
                <tr key={index} className="border-top">
                  <td>{tdata.itemCode}</td>
                  <td>{tdata.productName}</td>
                  <td>{tdata.productType}</td>
                  <td> {tdata.crystalType}</td>
                  {/* <td> {tdata.quantity}</td> */}
                  <td>
                    <Input
                      type="number"
                      value={productList[index].quantity}
                      onChange={(event) => {
                        const newQuantity = parseInt(event.target.value);
                        const availableQuantity = quantityList[index]; // Accessing quantity from quantityList
                        console.log("New Quantity:", newQuantity);
                        console.log("Available Quantity:", availableQuantity);
                        const updatedQuantity =
                          newQuantity > availableQuantity
                            ? availableQuantity
                            : Math.max(newQuantity, 1); // Ensure the minimum value is 1
                        console.log("Updated Quantity:", updatedQuantity);
                        const newProductList = productList.map((item, idx) =>
                          idx === index
                            ? { ...item, quantity: updatedQuantity }
                            : item
                        );
                        setProductList(newProductList);
                      }}
                    />
                  </td>
                  <td> {tdata.status}</td>
                  <td>{tdata.weight}</td>
                  <td>
                    <Input
                      type="number"
                      value={editedPrices[index]} // The edited total price
                      onChange={(event) => handlePriceChange(event, index)}
                    />
                  </td>

                  <td> {new Date(tdata.listingDate).toLocaleDateString()}</td>
                  <td>
                    {" "}
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {productList.length > 0 && (
          <Button
            className="btn"
            color="primary"
            onClick={postingCart ? null : postCart}
          >
            {postingCart ? (
              <>
                <Spinner size="sm" color="light" /> Posting...
              </>
            ) : (
              "Post Cart"
            )}
          </Button>
        )}
      </CardBody>
    </Card>
  );
};
export default Cart;
