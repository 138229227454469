import { Col, Row } from "reactstrap";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";
import TopCards from "../components/dashboard/TopCards";
import DoughnutChart from "../components/dashboard/DoughnutChart";
import bg1 from "../assets/images/bg/bg1.jpg";
import bg2 from "../assets/images/bg/bg2.jpg";
import bg3 from "../assets/images/bg/bg3.jpg";
import bg4 from "../assets/images/bg/bg4.jpg";
import { useEffect, useState } from "react";
import Axios from "axios";
import IncomeChart from "../components/dashboard/IncomeChart";

const Starter = () => {
  const [expenseData, setExpenseData] = useState({
    monthlyIncome: 0,
    monthlyExpense: 0,
    totalCustomer: 0,
    monthlyProfit: 0,
    expense_Miscellaneous: 0,
    expense_Packaging: 0,
    expense_Logistics: 0,
    expense_One_Time_Charge: 0,
    income_Cash: 0,
    income_Esewa: 0,
    income_Company_Bank: 0,
    income_Personal_Bank: 0,
  });
  useEffect(() => {
    const fetchExpenseData = async () => {
      const today = new Date();
      console.log("today", today);
      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();
      const month = `${todayYear}-${String(todayMonth).padStart(2, "0")}`;
      // setCurrentDate((prevState) => ({
      //   ...prevState,
      //   month: `${todayYear}-${todayMonth}`,
      //   year: todayYear,
      // }));
      console.log("current month", month);
      console.log("current month", todayYear);
      //response.data.yearlyIncome.toFixed(2) ??
      try {
        const response = await Axios.get(`/api/B2CTable/${month}/${todayYear}`);
        console.log(response.data);
        if (response.status == 200) {
          setExpenseData({
            monthlyIncome: response.data.monthlyIncome,
            monthlyExpense: response.data.monthlyExpense,
            totalCustomer: response.data.totalCustomer,
            monthlyProfit: response.data.monthlyProfit,
            expense_Miscellaneous: response.data.expense_Miscellaneous,
            expense_Packaging: response.data.expense_Packaging,
            expense_Logistics: response.data.expense_Logistics,
            expense_One_Time_Charge: response.data.expense_One_Time_Charge,
            income_Cash: response.data.income_Cash,
            income_Esewa: response.data.income_Esewa,
            income_Company_Bank: response.data.income_Company_Bank,
            income_Personal_Bank: response.data.income_Personal_Bank,
          });
        } else {
          console.log("Response : ", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };

    fetchExpenseData();
  }, []);
  return (
    <div>
      {/***Top Cards***/}
      <Row>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-success text-success"
            title="Income"
            subtitle="Monthly Income"
            earning={`Rs ${expenseData.monthlyIncome}`}
            icon="bi bi-wallet"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-danger text-danger"
            title="Expense"
            subtitle="Monthly Expense"
            earning={`Rs ${expenseData.monthlyExpense}`}
            icon="bi bi-coin"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-warning text-warning"
            title="Total Customers"
            subtitle="No of Customers"
            earning={`Rs ${expenseData.totalCustomer}`}
            icon="bi bi-basket3"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-info text-into"
            title="Sales"
            subtitle="Profit"
            earning={`Rs ${expenseData.monthlyProfit}`}
            icon="bi bi-bag"
          />
        </Col>
      </Row>
      {/***Sales & Feed***/}
      <Row>
        <SalesChart />

        <Col sm="10" lg="6" xl="5" xxl="4">
          <DoughnutChart expenseData={expenseData} />
        </Col>
        <Col sm="6" lg="6" xl="5" xxl="4">
          <IncomeChart incomeData={expenseData} />
        </Col>
      </Row>
      {/***Table ***/}
      {/* <Row>
        <Col lg="12">
          <ProjectTables />
        </Col>
      </Row> */}
    </div>
  );
};

export default Starter;
