import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardGroup,
  Button,
  Row,
  Col,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const SoldB2B = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/B2BTable/getSold");
        setTableData(response.data); // Update the tableData state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const fetchData = async (start, end) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `api/B2BTable/sortSold?start=${start}&end=${end}`
      );
      setTableData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  // Empty dependency array means this effect runs once when the component mounts
  const cardStyle = {
    height: "500px", // Adjust the height as needed
  };
  return (
    <Row>
      <div>
        <FormGroup>
          <Label for="startDate">Start Date</Label>
          <Input
            type="date"
            name="startDate"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">End Date</Label>
          <Input
            type="date"
            name="endDate"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </FormGroup>
        <Button
          color="primary"
          onClick={() => fetchData(startDate, endDate)}
          className="my-3"
        >
          Search
        </Button>
      </div>
      <div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <Card>
            <CardBody>
              <CardTitle tag="h5">Sold B2B Products</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                List of bulk products sold
              </CardSubtitle>

              <Table
                className="no-wrap mt-3 align-middle table-bordered border-primary table-striped"
                style={{ fontSize: "12px" }}
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Customer Name</th>
                    <th>Product Name</th>
                    <th>Type</th>
                    <th>Sale Price</th>
                    <th>Amount Sold</th>
                    <th>Sold Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata, index) => (
                    <tr key={index} className="border-top">
                      <td>{tdata.itemCode}</td>
                      <td> {tdata.customerName}</td>
                      <td>{tdata.productName}</td>
                      <td>{tdata.productType}</td>
                      <td> {tdata.salePrice}</td>
                      <td>{tdata.amountSold}</td>

                      <td>
                        {" "}
                        {new Date(tdata.purchasedDate).toLocaleDateString()}
                      </td>
                      <td>{tdata.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </div>
    </Row>
  );
};

export default SoldB2B;
