import React from "react";
import Barcode from "react-barcode";

const BarcodeGenerator = ({ value }) => {
  return (
    <div>
        <Barcode
        value={value}
        format="CODE128"
        width={2}
        height={100}
        displayValue={true}
        fontOptions="bold"
        textAlign="center"
        textMargin={5}
        fontSize={20}
        background="#ffffff"
        lineColor="#000000"
      />
    </div>
  );
};

export default BarcodeGenerator;
