import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  Spinner,
} from "reactstrap";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';


import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import axios from "axios";

const AddB2CWithMaterials = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ItemCode: "",
    ProductName: "",
    ProductType: "",
    crystalType: "",
    quantity: 0,
    status: "",
    weight: "",
    salePrice: "",
    purchasePrice: "",
    listingDate: "",
    Remarks: "",
    Source: "",
  });
  const generateItemCode = (productName, bulkQuantity) => {
    const nameKey = productName.substring(0, 3).toUpperCase();
    // Format the item code
    return `IND-${nameKey}-${bulkQuantity}`;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value };

    if (name === "ProductName" || name === "crystalType") {
      const newItemCode = generateItemCode(
        name === "ProductName" ? value : formData.ProductName,
        name === "crystalType" ? value : formData.crystalType
      );
      newFormData = { ...newFormData, ItemCode: newItemCode };
    }

    setFormData(newFormData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      const selectedDate = new Date(formData.Date);
      const isoDateString = selectedDate.toISOString();
      // formDataToSend.append("itemCode", formData.ItemCode);
      // formDataToSend.append("productName", formData.ProductName);
      // formDataToSend.append("productType", formData.ProductType);
      // formDataToSend.append("crystalType", formData.crystalType);
      // formDataToSend.append("quantity", formData.quantity);
      // formDataToSend.append("status", formData.status);
      // formDataToSend.append("weight", formData.weight);
      // formDataToSend.append("salePrice", formData.salePrice);
      // formDataToSend.append("purchasePrice", formData.purchasePrice);
      // formDataToSend.append("listingDate", isoDateString);
      console.log(
        "submit garna agi ko material length ; ",
        materialList.length
      );
      const formObjectToSend = {
        itemCode: formData.ItemCode,
        productName: formData.ProductName,
        productType: formData.ProductType,
        crystalType: formData.crystalType,
        quantity: formData.quantity,
        status: formData.status,
        weight: formData.weight,
        remarks: formData.Remarks,
        source: formData.Source,
        salePrice: formData.salePrice,
        purchasePrice: formData.purchasePrice,
        listingDate: isoDateString,
        materials: materialList,
      };
      console.log(
        "Submit huna lageko json : ",
        JSON.stringify(formObjectToSend)
      );
      // for (const entry of formDataToSend.entries()) {
      //   const [fieldName, fieldValue] = entry;
      //   console.log(`${fieldName}: ${fieldValue}`);
      // }
      // const response = await Axios.post("/api/B2BTable", formDataToSend);
      setLoading(true);
      const response = await Axios.post(
        "/api/B2CTable",
        JSON.stringify(formObjectToSend),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("formDataToSend:", formDataToSend);

      console.log("Response:", response.data);
      if (response.status === 201) {
        // Success
        alert("Product added successfully!");
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      } else {
        // Display an error message
        alert("Failed to add product. Please try again.");
      }
      // Add any additional logic you need after a successful submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  //add materials
  const [openMaterialModal, setMaterialModal] = useState(false);
  const addMaterial = () => {
    setMaterialModal(true);
  };

  const closeMaterialModal = () => {
    setMaterialModal(false);
  };

  const [materialData, setMaterialData] = useState({
    materialName: "",
    materialCode: "",
    costPerGram: "",
    totalCost: 0,
    weight: 0,
    purchaseDate: "",
  });
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    fetchMaterialData();
  }, []);
  const fetchMaterialData = async () => {
    try {
      const response = await axios.get("/api/B2CTable/GetMaterialsName");
      if (response.data) {
        setMaterials(response.data);
      }
    } catch (error) {
      console.error("There was an error fetching the material names:", error);
    }
  };

  const handleChangeMaterial = (event) => {
    setMaterialData({
      ...materialData,
      materialCode: event.target.value,
    });
  };

  const [materialList, setMaterialList] = useState([]);
  const [originalMaterialList, setOriginalMaterialList] = useState([]);
  const saveMaterial = async () => {
    console.log("material ko length :", materialList.length);
    let totalCost = 0;
    for (let i = 0; i < materialList.length; i++) {
      totalCost += materialList[i].totalCost;
    }
    setFormData({ ...formData, purchasePrice: totalCost });
    closeMaterialModal();
  };

  //fetch material data
  const fetchMaterialdata = async () => {
    if (
      materialData.materialCode.trim() === null ||
      materialData.materialCode.trim() === ""
    ) {
      toast.alert("Enter material code");
    } else if (
      materialList.some(
        (material) => material.materialCode === materialData.materialCode
      )
    ) {
      toast.alert("Material already added");
    } else {
      try {
        const response = await axios.get(
          `/api/B2CTable/MaterialStock/${materialData.materialCode}`
        );
        if (response.status === 200) {
          const newMaterialData = {
            materialName: response.data.materialName,
            materialCode: response.data.materialCode,
            costPerGram: response.data.costPerGram,
            totalCost: response.data.totalCost,
            weight: response.data.weight,
            purchaseDate: response.data.purchaseDate,
          };
          setMaterialData(newMaterialData);

          setMaterialList([...materialList, newMaterialData]);
          setOriginalMaterialList([...materialList, newMaterialData]);
        } else if (response.status === 400) {
          toast.warning("Material out of stock");
        } else if (response.status === 404) {
          toast.warning("Material not found");
        } else {
          toast.error("Server Error : ", response.status);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {" "}
                <i className="bi bi-bell me-2"> </i>
                Add B2B Product
              </div>
              <Button className="btn mt-2  btn-primary" type='button' onClick={addMaterial}>
                Add Materials
              </Button>
            </div>
          </CardTitle>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="ItemCode">Item Code</Label>
                <Input
                  id="ItemCode"
                  name="ItemCode"
                  type="text"
                  value={formData.ItemCode}
                  readOnly
                />
              </FormGroup>
              <FormGroup>
                <Label for="ProductName">Product Name</Label>
                <Input
                  id="ProductName"
                  name="ProductName"
                  placeholder="Product Name"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="ProductType">Product Type</Label>
                <Input
                  id="ProductType"
                  name="ProductType"
                  placeholder="Product Type"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="crystalType">Crystal Type</Label>
                <Input
                  id="crystalType"
                  name="crystalType"
                  placeholder="Crystal Type"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  id="status"
                  name="status"
                  placeholder="Status"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="weight">Weight</Label>
                <Input
                  id="weight"
                  name="weight"
                  placeholder="Weight"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Source">Source</Label>
                <Input
                  id="Source"
                  name="Source"
                  placeholder="Source"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Remarks">Remarks</Label>
                <select
                  id="Remarks"
                  name="Remarks"
                  onChange={handleChange}
                  defaultValue=""
                  className="form-control"
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="Miscellaneous">Miscellaneous</option>
                  <option value="Packaging">Packaging</option>
                  <option value="Logistics">Logistics</option>
                  <option value="One Time Charge">Logistics</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="salePrice">Sale Price</Label>
                <Input
                  id="salePrice"
                  name="salePrice"
                  placeholder="Sale Price"
                  type="number"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="purchasePrice">Purchase Price</Label>
                <Input
                  id="purchasePrice"
                  name="purchasePrice"
                  placeholder="Purchase Price"
                  type="number"
                  value={formData.purchasePrice}
                  // readOnly
                   onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="listingDate">Select Date</Label>
                <Input
                  type="date"
                  name="Date"
                  id="listingDate"
                  placeholder="Listing Date"
                  onChange={handleChange}
                />
              </FormGroup>

              {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="submit" className="mt-2">
                  Submit
                </Button>
                <Button className="mt-2" onClick={addMaterial}>
                  Add Materials
                </Button>
              </div> */}
              <Button type="submit" className="mt-2" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
            <Modal
              isOpen={openMaterialModal}
              toggle={closeMaterialModal}
              size="xl"
            >
              <Card>
                <CardTitle tag="h6" className="border-bottom p-3 mb-0">
                  Materials required for the product
                </CardTitle>
                <CardBody>
                  {/* material code */}
                  <Form>
                    <FormGroup>
                      <Label for="materialCode">Material Code</Label>
                      <select
                        id="materialCode"
                        name="materialCode"
                        value={materialData.materialCode}
                        onChange={handleChangeMaterial}
                        className="form-control"
                      >
                        <option value="">Select Material</option>
                        {materials.map((material, index) => (
                          <option key={index} value={material}>
                            {material}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Form>
                  <Button className="mt-2" onClick={fetchMaterialdata}>
                    Refresh Materials
                  </Button>
                  {materialList.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <h6>Maetrial List</h6>
                    </div>
                  )}
                  {materialList.length > 0 && (
                    <Table
                      className="no-wrap mt-3 align-middle"
                      responsive
                      borderless
                    >
                      <thead>
                        <tr>
                          <th>Material Code</th>
                          <th>Material Name</th>
                          <th>Cost Per Gram</th>
                          <th>Total Cost</th>
                          <th>Weight</th>
                          <th>Purchase Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {materialList.map((mData, index) => (
                          <tr key={index} className="border-top">
                            <td>{mData.materialCode}</td>
                            <td>{mData.materialName}</td>
                            <td>{mData.costPerGram}</td>
                            <td> {mData.costPerGram * mData.weight}</td>
                            <td>
                              <Input
                                type="number"
                                value={materialList[index].weight}
                                onChange={(event) => {
                                  const newWeight = parseInt(
                                    event.target.value
                                  );
                                  const availableWeight =
                                    originalMaterialList[index].weight; // Accessing quantity from quantityList

                                  const updatedWeight =
                                    newWeight > availableWeight
                                      ? availableWeight
                                      : Math.max(newWeight, 1); // Ensure the minimum value is 1

                                  const newMaterialList = materialList.map(
                                    (item, idx) =>
                                      idx === index
                                        ? {
                                            ...item,
                                            weight: updatedWeight,
                                            totalCost:
                                              updatedWeight * mData.costPerGram,
                                          }
                                        : item
                                  );
                                  setMaterialList(newMaterialList);
                                }}
                              />
                            </td>
                            {/* <td> {mData.weight}</td> */}

                            <td>
                              {" "}
                              {new Date(
                                mData.purchaseDate
                              ).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {materialList.length > 0 && (
                    <Button className="mt-2" onClick={saveMaterial}>
                      Save Material
                    </Button>
                  )}
                </CardBody>
              </Card>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default AddB2CWithMaterials;
