import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import Axios from "axios";

import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import axios from "axios";

const AddB2C = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ItemCode: "",
    ProductName: "",
    ProductType: "",
    crystalType: "",
    quantity: 0,
    status: "",
    weight: "",
    salePrice: "",
    purchasePrice: "",
    listingDate: "",
    Remarks: "",
    Source: "",
  });
  const generateItemCode = (productName, bulkQuantity) => {
    const nameKey = productName.substring(0, 3).toUpperCase();
    // Format the item code
    return `IND-${nameKey}-${bulkQuantity}`;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value };

    if (name === "ProductName" || name === "crystalType") {
      const newItemCode = generateItemCode(
        name === "ProductName" ? value : formData.ProductName,
        name === "crystalType" ? value : formData.crystalType
      );
      newFormData = { ...newFormData, ItemCode: newItemCode };
    }

    setFormData(newFormData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();
      const selectedDate = new Date(formData.Date);
      const isoDateString = selectedDate.toISOString();

      const formObjectToSend = {
        itemCode: formData.ItemCode,
        productName: formData.ProductName,
        productType: formData.ProductType,
        crystalType: formData.crystalType,
        quantity: formData.quantity,
        status: formData.status,
        weight: formData.weight,
        salePrice: formData.salePrice,
        remarks: formData.Remarks,
        source: formData.Source,
        purchasePrice: formData.purchasePrice,
        listingDate: isoDateString,
        materials: [],
      };
      console.log(
        "Submit huna lageko json : ",
        JSON.stringify(formObjectToSend)
      );
      setLoading(true);

      const response = await Axios.post(
        "/api/B2CTable",
        JSON.stringify(formObjectToSend),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("formDataToSend:", formDataToSend);

      console.log("Response:", response.data);
      if (response.status === 201) {
        // Success
        alert("Product added successfully!");
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      } else {
        // Display an error message
        alert("Failed to add product. Please try again.");
      }
      // Add any additional logic you need after a successful submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
            Add B2B Product
          </CardTitle>
          <CardBody>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="ItemCode">Item Code</Label>
                <Input
                  id="ItemCode"
                  name="ItemCode"
                  type="text"
                  value={formData.ItemCode}
                  readOnly
                />
              </FormGroup>
              <FormGroup>
                <Label for="ProductName">Product Name</Label>
                <Input
                  id="ProductName"
                  name="ProductName"
                  placeholder="Product Name"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="ProductType">Product Type</Label>
                <Input
                  id="ProductType"
                  name="ProductType"
                  placeholder="Product Type"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="crystalType">Crystal Type</Label>
                <Input
                  id="crystalType"
                  name="crystalType"
                  placeholder="Crystal Type"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  id="quantity"
                  name="quantity"
                  placeholder="Quantity"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  id="status"
                  name="status"
                  placeholder="Status"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="weight">Weight</Label>
                <Input
                  id="weight"
                  name="weight"
                  placeholder="Weight"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="salePrice">Sale Price</Label>
                <Input
                  id="salePrice"
                  name="salePrice"
                  placeholder="Sale Price"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="purchasePrice">Purchase Price</Label>
                <Input
                  id="purchasePrice"
                  name="purchasePrice"
                  placeholder="Purchase Price"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="listingDate">Select Date</Label>
                <Input
                  type="date"
                  name="Date"
                  id="listingDate"
                  placeholder="Listing Date"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Source">Source</Label>
                <Input
                  id="Source"
                  name="Source"
                  placeholder="Source"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label for="Remarks">Remarks</Label>
                <select
                  id="Remarks"
                  name="Remarks"
                  onChange={handleChange}
                  defaultValue=""
                  className="form-control"
                >
                  <option value="" disabled>
                    Select your option
                  </option>
                  <option value="Miscellaneous">Miscellaneous</option>
                  <option value="Packaging">Packaging</option>
                  <option value="Logistics">Logistics</option>
                  <option value="One Time Charge">Logistics</option>
                </select>
              </FormGroup>

              <Button type="submit" className="mt-2" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AddB2C;
